import Home from 'containers';

import 'styles/_base.scss';

function App() {
    return (
        <div>
            <Home />
        </div>
    );
}

export default App;
