import styles from './styles.scss';

const Home = () => {
    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>Quizmania</h1>
        </div>
    );
};

export default Home;
